import { useCallback, useEffect, useState } from "react";
import { UserInfo } from "@microsoft/teamsfx";
import { Button, MessageBar, MessageBarBody, Spinner } from "@fluentui/react-components";
import config from "../config";

const clockRetryTimes = 3;

interface TeamsUserData {
  interface: string,
  teamsUserInfo: UserInfo,
  teamsUserToken: string,
}

export default function Login(props: { tokenSet: [string | null, React.Dispatch<React.SetStateAction<string | null>>], teamsUserData: TeamsUserData }) {
  const { tokenSet, teamsUserData } = {
    ...props,
  };
  const token = tokenSet[0];
  const setToken = tokenSet[1];
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [clock, setClock] = useState<number>(0);

  const handleMTLogin = useCallback(async (mToken: string, source: string, channel: string) => {
    setLoading(true);
    const loginData = {
      type: "microsoft",
      source: source,
      channel: channel,
      token: mToken
    }
    const apiUrl = config.apiEndpoint + "auth/tlogin";
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginData)
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          setToken(data.result);
          setMessage("");
          setLoading(false);
          setClock(0);
        } else {
          throw new Error(data.result.message);
        }
      })
      .catch(error => {
        setToken("")
        console.error(error);
        setMessage(error.toString());
        setLoading(false);
        setClock(clock + 1);
      });
  }, [clock, setToken]);

  useEffect(() => {
    if (!token && teamsUserData.interface === "teams" && clock < clockRetryTimes)
      handleMTLogin(teamsUserData.teamsUserToken, teamsUserData.interface, config.apiInterface);
  }, [token, clock, teamsUserData, handleMTLogin]);

  return (
    <>
      {
        loading ?
          <Spinner labelPosition="below" label={`Loading(${clock})...`} />
          :
          <>
            {message !== "" ?
              <MessageBar intent={"error"}>
                <MessageBarBody>
                  {message}
                </MessageBarBody>
              </MessageBar>
              :
              null}
            <Button onClick={() => handleMTLogin(teamsUserData.teamsUserToken, teamsUserData.interface, config.apiInterface)}>Login</Button>
          </>
      }
    </>
  );
}
