// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";
import { useState, useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import Authentication from "./Authentication";
import Tab from "./tab/Main";
import config from "../config";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const [token, setToken] = useState<string | null>(null);
  const [subPageId, setSubPageId] = useState<string>("");
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  useEffect(() => {
    loading &&
      app.initialize().then(async () => {
        // Hide the loading indicator.
        app.notifySuccess();
        const context = await app.getContext();
        setSubPageId(context.page.subPageId!);
      });
  }, [loading]);
  
  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
              ? teamsHighContrastTheme
              : {
                ...teamsLightTheme,
                colorNeutralBackground3: "#eeeeee",
              }
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <Router>
          {!loading && (
            <Routes>
              <Route path="/tab" element={<Authentication route={<Tab tokenSet={[token, setToken]} subPageId={subPageId} theme={themeString}/>} athentication={true} tokenSet={[token, setToken]}  />} />
              <Route path="*" element={<Navigate to={"/tab"} />}></Route>
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
