const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  appName: "cht_portal",
  appTitle: "中華任意門",
  appVersion: "3.0.0",
  apiEndpoint: "https://chtportalapi-webapp.azurewebsites.net/api/",
  apiInterface: "cht_portal_teams",
};

export default config;
