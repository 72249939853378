import { useContext } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { app } from "@microsoft/teams-js";
import { Spinner } from "@fluentui/react-components";
import { TeamsFxContext } from "./Context";
import Login from "./Login";

export default function Authentication(props: { route?: JSX.Element, athentication?: boolean, tokenSet: [string | null, React.Dispatch<React.SetStateAction<string | null>>] }) {
  const { athentication, route, tokenSet } = {
    route: null,
    athentication: false,
    ...props,
  };
  const token = tokenSet[0];
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const teamsUserInfo = await teamsUserCredential.getUserInfo();
      const teamsUserToken = (await teamsUserCredential.getToken(""))!.token;
      return { teamsUserInfo, teamsUserToken };
    }
  });
  const hubName = useData(async () => {
    await app.initialize();
    const context = await app.getContext();
    return context.app.host.name;
  })?.data;
  const teamsUserData = loading || error ? undefined : data;
  return (
    <>
      {loading ?
        <Spinner /> :
        error ?
          `Error page: ${error}` :
          !["Teams", "TeamsModern", "Outlook"].includes(hubName?.toString()!) ?
            `Error page: Not in Microsoft app. (${hubName?.toString()})` :
            athentication ?
              token ?
                route :
                <Login
                  tokenSet={tokenSet}
                  teamsUserData={{
                    interface: "teams",
                    teamsUserInfo: teamsUserData!.teamsUserInfo,
                    teamsUserToken: teamsUserData!.teamsUserToken
                  }}
                /> :
              route
      }
    </>
  );
}
